import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";

const ErrorPage: React.FC = () => {
    return (<>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Northstar error page</title>
        </Helmet>
        <h2 className="font-bold">
            Sorry, an error has occurred! Please reach out to the Elevate Team!
        </h2>
    </>
    )
}

export default ErrorPage;